<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-row
            align="start"
          >
            <v-col>
              <v-select
                v-model="i_CurrentSeminarCenterID"
                :items="a_SeminarCenter"
                item-text="s_Name"
                item-value="i_SeminarCenterID"
                filled
                label="Standort"
                dense
                outlined
                color="success"
                @change="setSeminarCenterID"
                class="mt-3"
              />
            </v-col>
            <v-col
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="a_Teachers"
            :loading="b_Loading"
            item-key="i_AddressRoleID"
            class="elevation-1 table-one"
            multi-sort
            dense
          >
            <template v-slot:item.s_Lastname="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Lastname }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_Firstname="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Firstname }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_Action="{item}">
              <div class="d-flex align-center">
                <v-btn 
                  icon
                  @click="setImpersonated(item)"
                >
                  <v-icon
                    :color="item.b_Impersonated ? 'primary' : 'grey'"
                  >
                    mdi-account-convert
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { get } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      title: 'Lehrer',
    },
    watch: {
      i_CurrentSeminarCenterID: function (val) {
        this.getTeachers()
      },
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: this.$t('general.lastname'),
            align: 'start',
            sortable: true,
            value: 's_Lastname',
          },
          {
            text: this.$t('general.firstname'),
            align: 'start',
            sortable: true,
            value: 's_Firstname',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 's_Action',
          },
        ],
        a_Teachers: [],
        a_SeminarCenter: [],
        i_CurrentSeminarCenterID: null,
        b_Loading: true
      }
    },
    beforeMount () {
      this.a_SeminarCenter = this.getSeminarCenter
      if (this.a_SeminarCenter.length > 0) {
        this.i_CurrentSeminarCenterID = this.a_SeminarCenter[0].i_SeminarCenterID
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getSeminarCenter']),
    },
    methods: {
      ...mapActions(['setImpersonation']),
      getTeachers () {
        this.b_Loading = true
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher`, {
          i_AddressRoleID: this.$smt.getAddressRoleID(this.$route.meta.role),
          i_CompanyID: process.env.VUE_APP_SMT_COMPANY_ID,
          i_SeminarCenterID: this.i_CurrentSeminarCenterID
        })
          .then(response => {
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.a_Teachers = response.Teachers.sort((a, b) => a.s_Lastname > b.s_Lastname ? 1 : -1)
            this.b_Loading = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setSeminarCenterID (val) {
        this.i_CurrentSeminarCenterID = val
      },
      setImpersonated (Ao_Teacher) {
        this.a_Teachers.forEach(teacher => {
          teacher.b_Impersonated = false
        });
        Ao_Teacher.b_Impersonated=true
        this.setImpersonation({
          b_Status: true,
          i_AddressRoleID: Ao_Teacher.i_AddressRoleID,
          s_FullName: Ao_Teacher.s_Firstname + ' ' + Ao_Teacher.s_Lastname,
          s_Role: config.roles[1],
          a_PlanningPeriods: Ao_Teacher.a_PlanningPeriods
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
